"use client";

import styles from "./css/DesktopMenu.module.scss";
import { usePathname } from "next/navigation";
import { linkUrl, isLinkActive } from "@/helpers";
import ImageWithFallback from "@/components/ImageWithFallback/ImageWithFallback";
import { formatUrl } from "@/helpers/formatters";
import LoadingLink from "@/components/LoadingLink/LoadingLink";
import Image from "next/image";

export default function DesktopMenu({ transparent, logo, menu }: {
  readonly transparent?: boolean,
  readonly logo: Record<string, any>,
  readonly menu: Record<string, any>[],
}) {
  const pathname = usePathname();

  menu?.forEach((item: Record<string, any>) => {
    if(Array.isArray(item?.sublink)) item.sublink?.forEach((sub: Record<string, any>) => {
      let cap = sub?.caption?.split(']');
      if(cap?.length === 2) {
        if(cap[0].charAt(0) === '[') {
          cap[0] = cap[0].slice(1);
          sub.caption = cap[1];
          sub.icon = cap[0];
        }
      }
    });
  });

  return (
    <div className={`${styles['desktop-menu']} ${transparent ? styles.transparent : ''} hidden lg:block`}>
      <div className="container flex justify-between items-center !pt-3 !pb-1">

        <div className={styles.logo}>
          <LoadingLink href="/" title={logo?.caption ?? 'Doping Hafıza'}>
            <ImageWithFallback
              src={logo?.url ? linkUrl(process.env.NEXT_PUBLIC_API_ROOT ?? '', logo?.url) : (process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/public_assets/images/doping-hafiza-logo-14.svg'}
              fallback={(process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/public_assets/images/doping-hafiza-logo-14.svg'}
              alt={logo?.alternativeText ?? logo?.caption ?? 'Doping Hafıza'}
              loading="lazy" //! priority={true}
              width={logo?.width ?? 244}
              height={logo?.height ?? 70}
              className="w-[244px] h-[70px]"
            />
          </LoadingLink>
        </div>

        <nav>
          <ul className="flex justify-between items-center text-[13px] xl:text-sm">
            {
              menu.map((item: Record<string, any>, index: number) => {
                return (
                  <li className={`${item?.sublink?.length ? styles['has-submenu'] : ''} ml-5 xl:ml-9`} key={item.id}>
                    <LoadingLink
                      className={`text-slate-100 hover:text-white transition-all ${isLinkActive(pathname, item.url, styles.active, styles['exact-match'], item?.sublink?.length ? item.sublink.map((sub: Record<string,any>) => formatUrl(sub.url, true)) : undefined)}`}
                      href={item.url}
                      target={item?.target === 'blank' ? '_blank' : '_self'}
                      rel={item?.target === 'blank' ? 'noopener noreferrer' : undefined}
                      title={item?.title ?? 'Doping Hafıza ' + item.caption}>
                      { item.caption }
                    </LoadingLink>

                    {
                      item?.sublink?.[0] &&
                      (
                        <div className={`${styles.submenu} ${index / menu.length < .5 ? styles.left : styles.right}`}>
                          <ul className="flex flex-col items-start">
                            {
                              item.sublink.map((sub: Record<string, any>) => {
                                return (
                                  <li key={sub.id}>
                                    <LoadingLink
                                      href={sub.url}
                                      title={sub?.title ?? 'Doping Hafıza ' + sub.caption}
                                      target={sub?.target === 'blank' ? '_blank' : '_self'}
                                      rel={item?.target === 'blank' ? 'noopener noreferrer' : undefined}
                                      className={isLinkActive(pathname, sub.url, styles.active, styles['exact-match'])}
                                    >
                                      {
                                        sub?.icon && (
                                          <Image
                                            src={`/assets/images/icons/${sub.icon}`}
                                            alt="Doping Hafıza"
                                            loading="lazy"
                                            width={18}
                                            height={18}
                                            className="inline-block -ms-2 me-2"
                                          />
                                        )
                                      }
                                      { sub.caption }
                                    </LoadingLink>
                                  </li>
                                );
                              })
                            }
                          </ul>
                        </div>
                      )
                    }
                  </li>
                );
              })
            }
          </ul>

        </nav>
      </div>
    </div>
  );
}
  