"use client";

import DPIcon from "@/components/DPIcon/DPIcon";
import styles from "./css/MobileMenu.module.scss";
import { useEffect, useId, useState } from "react";
import Image from "next/image";
import _e from "@/helpers/texts";
import LoadingLink from "@/components/LoadingLink/LoadingLink";

const ImageItem = ({ item }: Record<string, any>) => {
  if(item?.image) return (
    <>
      <Image src={item.image} width={item?.width ?? 24} height={item?.height ?? 24} alt={item?.caption ?? item.title} />
      { item?.caption && <span className="text-[10px] leading-normal font-semibold block">{ item.caption }</span> }
    </>
  );

  return (
    <>
      <DPIcon className={styles.icon} icon={item?.icon} />
      { item?.caption && <span className="text-[10px] leading-normal font-semibold block">{ item.caption }</span> }
    </>
  );
};

const LinkItem = ({ item }: Record<string, any>) => {
  if(item?.link) return (
    <LoadingLink
      target={item?.target ?? '_self'}
      rel={item?.target === '_blank' ? 'noopener noreferrer' : undefined}
      href={item.link}
      className="flex flex-col justify-center items-center w-full"
      title={item?.title ?? item?.caption ?? ''}>
      <ImageItem item={item} />
    </LoadingLink>
  );

  return (
    <div className="flex flex-col justify-center items-center w-full" title={item?.title ?? item?.caption ?? ''}>
      <ImageItem item={item} />
    </div>
  );
};

export default function MobileMenu({ data, menuState, menuToggle }: {
  readonly data: Record<string,any>,
  readonly menuState?: boolean,
  readonly menuToggle: Function,
}) {

  const tabs: Record<string,any>[] = data?.menu?.map((item: Record<string,any>) => {
    const tab = {
      id: item.id,
      title: item?.title,
      caption: item?.title,
      icon: item?.icon,
      link: item?.sublink?.length ? undefined : item?.url,
      tab: {
        header: item?.caption,
        headerLink: item?.url,
        subs: [] as Record<string,any>[]
      }
    };

    tab.tab.subs = item?.sublink?.map((sub: Record<string,any>) => {
      return {
        id: sub.id,
        link: sub?.url,
        text: sub?.caption,
      };
    });

    if(item?.sublink?.length && item?.url && item.url !== '/hakkimizda') tab.tab.subs.push({
      id: '_view_all_item_' + item.id,
      link: item.url,
      text: _e('Tümünü Göster'),
      icon: 'arrow-circle-right',
      strong: true
    });

    return tab;
  });

  const tabId = useId();
  [
    { id: tabId + '_0', title: "İOS Uygulamasını İndir", target: '_blank', icon: 'navicons-appstore', link: data?.app_store_url ?? 'https://apps.apple.com/app/id1552739422?l=tr' },
    { id: tabId + '_1', title: "Android Uygulamasını İndir", target: '_blank', image: process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/global/navicons-google-play.png', link: data?.play_store_url ?? 'https://play.google.com/store/apps/details?id=com.dopinghafiza.mobile.app&hl=tr' },
    { id: tabId + '_2', title: "Android Uygulamasını İndir", target: '_blank', image: process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/global/huawei.svg', width: 24, height: 23, link: data?.app_gallery_url ?? 'https://play.google.com/store/apps/details?id=com.dopinghafiza.mobile.app&hl=tr' },
  ].forEach((item: Record<string,any>) => tabs.push(item));

  const [ currentTab, setCurrentTab ] = useState<string|number>(tabs?.[0]?.id);
  
  const tabSelect = ((id: string|number) => {
    const selected = tabs.find((item: any) => item.id === id);
    
    if(selected && selected?.tab) setCurrentTab(id);
  });

  useEffect(() => {
    const handleResize = () => {
      menuToggle(false);
    };

    window?.addEventListener('resize', handleResize);
    return () => window?.removeEventListener('resize', handleResize);
  }, [ menuToggle ]);
  
  return (
    <>
      <div className={`${styles.backdrop} ${menuState ? styles.active : ''} backdrop-blur-sm`} onClick={() => menuToggle()}></div>
      <div className={`${styles['mobile-menu']} ${menuState ? styles.active : ''} flex flex-col content-stretch lg:hidden`}>

        <div className="flex justify-start itemx-center h-20 flex-grow-0">
          <div className="flex justify-stretch items-center w-1/2 px-5 pt-1">
            <LoadingLink href="/">
              <Image src={`${process.env.NEXT_PUBLIC_ASSET_ROOT}/assets/public_assets/images/doping-hafiza-logo-14.svg`} width={244} height={70} alt="Doping Hafıza" />
            </LoadingLink>
          </div>
          <div className="flex justify-end items-center w-1/2 text-3xl px-5" >
            <DPIcon icon="close" onClick={() => menuToggle()}/>
          </div>
        </div>

        <div className="flex justify-start items-center gap-1 px-1 pb-1 flex-grow-0">
          <LoadingLink target="_blank" rel="noopener noreferrer" href={`${data?.whatsapp?.link ?? 'https://wa.me/905332362005'}?text=${data?.whatsapp?.text ?? 'Merhaba%2C%20bilgi%20almak%20istiyorum%21'}`} className={`${styles['btn-whatsapp']} flex items-center h-9 rounded-md border px-3 w-1/2 font-semibold`}>
            <DPIcon icon="whatsapp-original" />
            <span className="ms-1">{ _e('Whatsapp\'tan Yazın') }</span>
          </LoadingLink>
          <LoadingLink target="_blank" href={data?.phone?.url ?? 'tel:02122367441'} className={`${styles['btn-contact']} flex items-center h-9 rounded-md border px-3 flex-grow-1 w-1/2 font-semibold`}>
            <DPIcon icon="navicons-phone" className="text-sm" />
            <span className="ms-1">{ _e('Bizi Arayın') }</span>
          </LoadingLink>
        </div>

        <div className={`${styles.body} flex content-stretch items-stretch flex-grow`}>
          <nav className={`${styles.buttons} grow-0 w-[60px]`}>
            <ul className="select-none">
              {
                tabs.map((item: Record<string, any>) => {
                  return (
                    <li className={`flex items-center content-center w-[60px] h-[55px] ${currentTab === item.id ? styles.active : ''}`} key={item.id} onClick={() => {
                      if(!item?.link) tabSelect(item.id);
                    }}>
                      <LinkItem item={ item } />
                    </li>
                  );
                })
              }
            </ul>
          </nav>

          <div className={`${styles.content} flex flex-col justify-stretch items-stretch flex-grow`}>
            <div className={`${styles.subs} flex flex-col justify-stretch align-stretch flex-grow overflow-auto py-5`}>
              <div className="flex-grow overflow-auto relative">
                {
                  tabs.map((item: Record<string, any>) => {
                    if(item?.tab) return (
                      <div className={`${styles.item} ${currentTab === item.id ? styles.active : ''} px-5 overflow-auto`} key={item.id}>
                        {
                          item?.tab?.headerLink ? (
                            <LoadingLink href={item.tab.headerLink} className="text-lg font-semibold leading-7 px-1 mb-4 text-lava" onClick={() => menuToggle()}>{ item?.tab?.header }</LoadingLink>
                          ) : (
                            <h2 className="text-lg font-semibold leading-7 px-1 mb-4 text-lava">{ item?.tab?.header }</h2>
                          )
                        }
                        <ul>
                          {
                            item.tab?.subs?.map((sub: Record<string, any>) => {
                              return (
                                <li key={sub.id} className="text-base leading-normal border-b border-b-slate-100 py-3 px-1">
                                  <LoadingLink href={sub.link} className={`flex items-center content-start ${sub?.strong ? styles.strong + ' text-xs' : ''}`} onClick={() => menuToggle()}>
                                    <span className="pr-2">{ sub.text }</span>
                                    { sub?.icon && <DPIcon className="text-base" icon={sub.icon} /> }
                                  </LoadingLink>
                                </li>
                              );
                            })
                          }
                        </ul>
                      </div>
                    );
                  })
                }
              </div>
            </div>

            <div className="text-center px-5 py-3 text-xs">{data?.copyright ?? 'Copyright © Doping Hafıza'}</div>
          </div>
        </div>
      </div>
    </>
  );
}
  