"use client";

import DPIcon from "@/components/DPIcon/DPIcon";
import Image from "next/image";
import LoadingLink from "../LoadingLink/LoadingLink";

export const ContactLinks = ({ content }: Record<string, any>) => {
  const res: Record<string,any> = {
    waLink: {
      icon: 'whatsapp-white',
      link: (content.whatsapp?.link ?? 'https://wa.me/905332362005') + '?text=' + (content.whatsapp?.text ?? 'Merhaba, bilgi almak istiyorum.'),
      title: content.whatsapp?.title ?? 'Whatsapp ile Soru Sorun',
      caption: content.whatsapp?.caption ?? '',
      rel: 'noopener noreferrer',
    },
    phoneLink: {
      icon: 'phone',
      link: content.phone?.url ?? 'tel:+902122367441',
      title: content.phone?.title ?? 'Doping Hafıza',
      caption: content.phone?.caption ?? '',
    },
    emailLink: {
      icon: 'mc-envelope',
      link: content.email?.url ?? 'mailto:info@dopinghafiza.com',
      title: content.email?.title ?? 'Doping Hafıza',
      caption: content.email?.caption ?? '',
    },
  };

  if(res.phoneLink.link.indexOf('tel:') === -1) res.phoneLink.link = 'tel:' + res.phoneLink.link;
  if(res.emailLink.link.indexOf('mailto:') === -1) res.emailLink.link = 'mailto:' + res.emailLink.link;

  return (
    Object.keys(res).map((key: string) => {
      return (
        <LoadingLink href={res[key].link} target="_blank" rel={res[key]?.rel} title={res.title} key={key} className="flex gap-2 items-center">
          <DPIcon icon={res[key].icon} className="text-base" />
          <span className="title">{ res[key].caption }</span>
        </LoadingLink>
      );
    })
  );
};

export const SocialLinks = ({ content, className, type }: {
  readonly content: Record<string,any>,
  readonly className?: string,
  readonly type?: string,
}) => {
  if(!type) type = ''; //Dummy Line
  
  const res: Record<string,any> = {
    instagram: {
      link: content.instagram ?? 'https://www.instagram.com/dopinghafiza',
      title: 'Doping Hafıza Instagram',
      icon: 'instagram',
    },
    youtube: {
      link: content.youtube ?? 'https://www.youtube.com/user/dopinghafiza',
      title: 'Doping Hafıza Youtube',
      icon: 'youtube',
    },
    facebook: {
      link: content.facebook ?? 'https://www.facebook.com/dopinghafiza',
      title: 'Doping Hafıza Facebook',
      icon: 'facebook',
    },
    tiktok: {
      link: content.tiktok ?? 'https://www.tiktok.com/@dopinghafiza',
      title: 'Tiktok ile Soru Sorun',
      icon: 'tiktok',
      /* Image usage
      image: type === 'footer' ? process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/public_assets/svg/icons/tiktok-lava.svg' : process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/global/tiktok.svg',
      width: 14,
      height: 16,
      /* -o- */
    },
    twitter: {
      link: content.twitter ?? 'https://twitter.com/dopinghafiza/',
      title: 'Doping Hafıza Twitter',
      icon: 'x',
    },
  };

  return (
    Object.keys(res).map((key: string) => {
      return (
        <LoadingLink key={key} href={res[key].link} target="_blank" rel="noopener noreferrer" title={res[key].title} className={`${className ?? 'opacity-70 hover:opacity-100 transition-opacity'}`}>
          {
            (
              res[key].image
                ? <Image src={res[key].image} width={res[key].width} height={res[key].height} alt={res[key].title} className="w-[17px]" />
                : <DPIcon title={res[key].title} icon={res[key].icon} />
            )
          }
        </LoadingLink>
      );
    })
  );
};