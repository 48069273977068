"use client";

import { RefObject, useEffect, useRef, useState } from "react";
import ContactBar from "./components/ContactBar";
import DesktopMenu from "./components/DesktopMenu";
import MobileMenu from "./components/MobileMenu";
import styles from "./css/HeaderContainer.module.scss";

export default function HeaderContainer({ content, mobileMenu, animated, transparent, breakPointLow, breakPointHigh }: {
  readonly content: Record<string,any>,
  readonly mobileMenu: Record<string,any>,
  readonly animated?: boolean,
  readonly transparent?: boolean,
  readonly breakPointLow?: number,
  readonly breakPointHigh?: number,
}) {
  const data = { ...content };

  const header: RefObject<HTMLElement> = useRef(null);

  const [ hasStickyFooter, setHasStickyFooter ] = useState(false);

  const [ anim, setAnim ] = useState(styles['top-bar'] + ' ' + (animated ? styles['hidden-menu'] : ''));
  const [ trans, setTrans ] = useState(transparent ?? false);
  const [ menuState, setMenuState ] = useState(false);

  const menuToggle = (forceState?: boolean) => {
    setMenuState((current: boolean) => {
      if(forceState !== undefined) return forceState;
      
      return !current;
    });
  };

  useEffect(() => {
    const bpl = breakPointLow ?? 180;
    const bph = Math.max(bpl + 1, (breakPointHigh ?? 360));

    const onScroll = () => {
      const st = window?.document?.documentElement?.scrollTop;
      
      if(transparent) {
        if(st > bph) setTrans(false);
        if(st < bpl) setTrans(true);
      }
      
      if(animated) {
        if(
          st > bph
          && !header.current?.classList.contains(styles['visible-menu'])
        ) setAnim(styles['top-bar'] + ' ' + styles['visible-menu'] + ' ' + styles.animate);
        if(
          st < bpl
          && !header.current?.classList.contains(styles['hidden-menu'])
        ) setAnim(styles['top-bar'] + ' ' + styles['hidden-menu'] + ' ' + styles.animate);
      }

      /* If there is a Sticky Footer */
      const isMobile = window?.innerWidth < 1024;
      const stickyFooter = document?.querySelector('[data-footer-scroll-break-point]') as HTMLElement;
      const scrollBreakPoint = stickyFooter ? parseInt(stickyFooter?.dataset?.footerScrollBreakPoint ?? '0') : 0;
      
      setHasStickyFooter(stickyFooter ? true : false);
      
      const hgt = isMobile && scrollBreakPoint ? '0px' : '-44px';
      if(scrollBreakPoint && window?.scrollY > scrollBreakPoint) {
        header.current?.style.setProperty('transform', `translateY(${hgt})`);
      } else {
        header.current?.style.setProperty('transform', `translateY(0px)`);
      }

    };
    
    onScroll();

    window?.addEventListener('scroll', onScroll);
    window?.addEventListener('resize', onScroll);

    return () => {
      window?.removeEventListener('scroll', onScroll);
      window?.removeEventListener('resize', onScroll);
    };
  }, [
    animated,
    transparent,
    breakPointHigh,
    breakPointLow
  ]);
  
  return (
    <>
      {/*
      Sticky / fixed layer at the top of the page causes scroll behaviour to mess up.
      Placing a dom element to the top prevents that.
      Not a tasty solution but its the least expensive one...
      */}
      <div className="sticky-fix"></div>
      {/* -o- */}

      <header ref={header} className={`${styles.header} ${trans ? styles.trans : ''} ${anim} ${hasStickyFooter ? 'transition-all duration-300' : ''}`}>
        <ContactBar transparent={trans} menuToggle={menuToggle} content={data} />
        <DesktopMenu transparent={trans} logo={data?.logo ?? {}} menu={data?.menu ?? []} />
      </header>
      <MobileMenu menuState={menuState} data={mobileMenu} menuToggle={menuToggle} />
    </>
  );
}
